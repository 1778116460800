/** @format */

import { createContext, useState } from 'react'

export const ActionBar = createContext()

const ActionBarProvider = ({ children }) => {
  const [actions, setActions] = useState([])
  return (
    <ActionBar.Provider
      value={{
        setActions,
        actions,
        clear: () => setActions([]),
      }}
    >
      {children}
    </ActionBar.Provider>
  )
}

export default ActionBarProvider
