/** @format */

import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Image, Modal, ModalHeader, NavDropdown } from 'react-bootstrap'
import { ActionBar } from '../action-bar/action-bar.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faQuestion, faPencilAlt, faFloppyDisk, faX, faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import logo from '../../images/elionova.png'
import styles from './page-header.module.css'

const PageHeader = () => {
  const { actions } = useContext(ActionBar)
  const [showDelete, setShowDelete] = useState(false)
  const [action, setAction] = useState()

  const doDelete = () => {
    setShowDelete(false)
    action.f()
  }

  const a = (
    <>
      <Link to='/experiment'>
        <div>Experiments</div>
      </Link>
      <Link to='/result'>
        <div>Results</div>
      </Link>
      <Link to='/device'>
        <div>Devices</div>
      </Link>
      <Link to='/tenant'>
        <div>Tenants</div>
      </Link>
      <NavDropdown title='Settings' id='basic-nav-setting'>
        <Dropdown.Item as={Link} to='/product'>
          Products
        </Dropdown.Item>
        <Dropdown.Item as={Link} to='/order-template'>
          Order Templates
        </Dropdown.Item>
        <Dropdown.Item as={Link} to='/unit'>
          Units
        </Dropdown.Item>
        <Dropdown.Item as={Link} to='/sample'>
          Samples
        </Dropdown.Item>
      </NavDropdown>
      <Modal show={showDelete}>
        <Modal.Header>Delete</Modal.Header>
        <Modal.Body>Really delete?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
          <Button onClick={doDelete}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )

  const mapIcon = (type) => {
    switch (type) {
      case 'add':
        return <FontAwesomeIcon icon={faPlus} size='lg' color='white' />
      case 'edit':
        return <FontAwesomeIcon icon={faPencilAlt} size='lg' color='white' />
      case 'save':
        return <FontAwesomeIcon icon={faFloppyDisk} size='lg' color='white' />
      case 'cancel':
        return <FontAwesomeIcon icon={faX} size='lg' color='white' />
      case 'download':
        return <FontAwesomeIcon icon={faDownload} size='lg' color='white' />
      case 'delete':
        return <FontAwesomeIcon icon={faTrashAlt} size='lg' color='white' />
      default:
        return <FontAwesomeIcon icon={faQuestion} size='lg' color='white' />
    }
  }

  const callAction = (a) => {
    console.log('call action', a)
    if (a.type === 'delete') {
      setShowDelete(true)
      setAction(a)
    } else {
      a.f()
    }
  }

  return (
    <div className={styles.pageHeader}>
      <div className={styles.menu}>
        <Link to='/'>
          <div className={styles.home}>
            <Image src={logo} height={45} />
          </div>
        </Link>
        {a}
      </div>
      <div className={styles.actions}>
        <span>
          {actions.map((a) => (
            <div key={a.type}>
              <div onClick={() => callAction(a)}>{mapIcon(a.type)}</div>
            </div>
          ))}
        </span>
      </div>
    </div>
  )
}

export default PageHeader
