/** @format */

import { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Notifications from 'react-notify-toast'
import PageHeader from './components/page-header/page-header.component'
import ActionBarProvider from './components/action-bar/action-bar.component'
import WaitDialog from './components/wait-dialog/wait-dialog.component'

import './App.css'

const HomeScreen = lazy(() => import('./screens/home/home.screen'))
const AssignScreen = lazy(() => import('./screens/assign/assign'))
const CuvetteInfoScreen = lazy(() => import('./screens/cuvettes/info'))
const DevicesScreen = lazy(() => import('./screens/devices/overview'))
const DeviceDetailsScreen = lazy(() => import('./screens/devices/details'))
const DeviceEditScreen = lazy(() => import('./screens/devices/edit'))
const ExperimentOverview = lazy(() => import('./screens/experiment/overview'))
const ExperimentDetails = lazy(() => import('./screens/experiment/details'))
const ExperimentScreen = lazy(() => import('./screens/experiment/experiment'))
const OrdersScreen = lazy(() => import('./screens/orders/overview'))
const OrderDetailsScreen = lazy(() => import('./screens/orders/details'))
const OrderEditScreen = lazy(() => import('./screens/orders/edit'))
const OrderTemplateOverview = lazy(() => import('./screens/order-template/overview'))
const OrderTemplateDetails = lazy(() => import('./screens/order-template/details'))
const OrderTemplateEdit = lazy(() => import('./screens/order-template/edit'))
const ProductOverview = lazy(() => import('./screens/product/overview'))
const ProductDetails = lazy(() => import('./screens/product/details'))
const ProductEdit = lazy(() => import('./screens/product/edit'))
const ResultsScreen = lazy(() => import('./screens/results/results.screen'))
const ResultDetailsScreen = lazy(() => import('./screens/result-details/result-details.screen'))
const TenantsScreen = lazy(() => import('./screens/tenants/overview'))
const TenantDetailsScreen = lazy(() => import('./screens/tenants/details'))
const TenantEditScreen = lazy(() => import('./screens/tenants/edit'))
const UnitsOverviewScreen = lazy(() => import('./screens/units/overview'))
const UnitDetailsScreen = lazy(() => import('./screens/units/details'))
const UnitEditScreen = lazy(() => import('./screens/units/edit'))
const SamplesOverviewScreen = lazy(() => import('./screens/samples/overview'))
const SampleDetailsScreen = lazy(() => import('./screens/samples/details'))
const SampleEditScreen = lazy(() => import('./screens/samples/edit'))
const MigrateScreen = lazy(() => import('./screens/migrate'))
const MaskScreen = lazy(() => import('./screens/mask/mask'))

const App = () => {
  return (
    <div className='App'>
      <Router>
        <ActionBarProvider>
          <Notifications options={{ zIndex: 10000, top: '50px' }} />
          <PageHeader />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/' element={<HomeScreen />} />
              <Route path='/assign' element={<AssignScreen />} />
              <Route path='/cuvette/info' element={<CuvetteInfoScreen />} />
              <Route path='/device' element={<DevicesScreen />} />
              <Route path='/device/:id' element={<DeviceDetailsScreen />} />
              <Route path='/device/:id/edit' element={<DeviceEditScreen />} />
              <Route path='/experiment' element={<ExperimentOverview />} />
              <Route path='/experiment/new' element={<ExperimentScreen />} />
              <Route path='/experiment/:id' element={<ExperimentDetails />} />
              <Route path='/order' element={<OrdersScreen />} />
              <Route path='/order/:id' element={<OrderDetailsScreen />} />
              <Route path='/order/:id/edit' element={<OrderEditScreen />} />
              <Route path='/result' element={<ResultsScreen />} />
              <Route path='/result/:id' element={<ResultDetailsScreen />} />
              <Route path='/tenant' element={<TenantsScreen />} />
              <Route path='/tenant/:id' element={<TenantDetailsScreen />} />
              <Route path='/tenant/:id/edit' element={<TenantEditScreen />} />
              <Route path='/order-template' element={<OrderTemplateOverview />} />
              <Route path='/order-template/:id' element={<OrderTemplateDetails />} />
              <Route path='/order-template/:id/edit' element={<OrderTemplateEdit />} />
              <Route path='/product' element={<ProductOverview />} />
              <Route path='/product/:id' element={<ProductDetails />} />
              <Route path='/product/:id/edit' element={<ProductEdit />} />
              <Route path='/unit' element={<UnitsOverviewScreen />} />
              <Route path='/unit/:id' element={<UnitDetailsScreen />} />
              <Route path='/unit/:id/edit' element={<UnitEditScreen />} />
              <Route path='/sample' element={<SamplesOverviewScreen />} />
              <Route path='/sample/:id' element={<SampleDetailsScreen />} />
              <Route path='/sample/:id/edit' element={<SampleEditScreen />} />
              <Route path='/migration' element={<MigrateScreen />} />
              <Route path='/mask' element={<MaskScreen />} />
            </Routes>
          </Suspense>
        </ActionBarProvider>
      </Router>
      <WaitDialog />
    </div>
  )
}

export default App
